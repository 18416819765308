#content {
    color: white;
}

#backgroundImg {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

#cari {
    font-weight: 700;
    font-size: 80px;
    margin-top: 198px;
}

#cariText {
    font-style: normal;
    font-weight: 400;
    opacity: 0.6;
    margin-bottom: 220px;
    font-size: 24px;
}

#lebel {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    overflow-x: auto;
}

.lebelItem {
    border: 1px solid rgba(255,255,255, 0.2);
    border-radius: 100%;
    min-height: 208px;
    min-width: 208px;
    max-height: 208px;
    max-width: 208px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 30px;
}

.lebelNo {
    margin: 10px 0;
}

.lebelText {
    opacity: 0.5;
}

@media screen and (max-width:800px) {
    #backgroundImg {
        height: 625px;
        object-fit: cover;
        object-position: center;
    }

    #cari {
        font-size: 40px;
        line-height: 48px;
        margin-top: 248px;
    }

    #cariText {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 126px;
    }

    #lebel {
        justify-content: unset;
    }

    .lebelItem {
        min-height: 144px;
        min-width: 144px;
        max-height: 144px;
        max-width: 144px;
        margin: 0px 10px;
    }

    .lebelText {
        font-size: 13px;
    }
}