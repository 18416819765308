#navBar {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#webMenu {
    display: flex;
    justify-content: center;
    align-items: center;
}

#webMenu div {
    display: flex;
    align-items: center;
    margin: 0 20px;
}

#search img {
    margin-right: 5px;
}

#mobileMenu {
    display: none;
}

@media screen and (max-width:800px) {
    #webMenu {
       display: none;
    }

    #mobileMenu {
        display: flex;
        align-items: center;
    }

    #cartIcon {
       margin: 0px 22px 0px 22px;
       height: 28px;
    }
}